import {Button, TextField} from "@mui/material";
import React from "react";
import {isValidEmail} from "./Utils";
import {emailCodeRequest, emailCodeConfirm, deleteAccount} from "./Api";

type State = 'none' | 'codeRequested' | 'codeConfirmed';

const c = localStorage.getItem('d');
let _token = '';
let _email = '';
if (c) {
  try {
    const d = JSON.parse(c);
    _token = d.token;
    _email = d.email;
  } catch {
  }
}

export const Form = () => {
  const [email, setEmail] = React.useState(_email);
  const [code, setCode] = React.useState('');
  const [state, setState] = React.useState<State>(_email.length > 0 && _token.length > 0 ? 'codeConfirmed' : 'none');

  const onRequestEmailCodeClick = () => {
    if (isValidEmail(email)) {
      setState('codeRequested');
      emailCodeRequest(email);
    } else {
      alert('Please, enter valid email');
    }
  };

  const onConfirmEmailCodeClick = () => {
    if (code.length === 6) {
      emailCodeConfirm(email, code)
        .then((token) => {
          _email = email;
          _token = token;
          setState('codeConfirmed');
          localStorage.setItem('d', JSON.stringify({
            email,
            token,
          }));
        })
        .catch(() => {
          alert('You entered invalid email or code');
        });
    } else {
      alert('Please, enter 6 digits code');
    }
  };

  const onDeleteClick = () => {
    deleteAccount(_token)
      .then(() => {
        alert('Your account was successfully deleted. We already miss you ;( ...');
      })
      .catch(() => {
        alert('For some reason your account was not deleted. Please try again.');
      })
      .finally(() => {
        _email = '';
        _token = '';
        setEmail('');
        setCode('');
        setState('none');
        localStorage.removeItem('d');
      });
  };

  function renderActions() {
    switch (state) {
      case "none":
        return (
          <Button
            variant="contained"
            onClick={onRequestEmailCodeClick}
          >Get email confirmation code</Button>
        );
      case "codeRequested":
        return (
          <>
            <br />
            <div>Please, enter your code on your email</div>
            <TextField
              placeholder='6 digits code'
              type='text'
              size="small"
              margin='normal'
              value={code}
              onChange={(e) => {
                if (e.target.value.length <= 6) {
                  setCode(e.target.value);
                }
              }}
            />
            <br/>
            <Button variant="contained" onClick={onConfirmEmailCodeClick}>Confirm email code</Button>
          </>
        );
      case "codeConfirmed":
        return (
          <Button
            color='error'
            variant="contained"
            onClick={onDeleteClick}
          >DELETE MY ACCOUNT FULLY</Button>
        );
    }
  }

  return (
    <div style={{padding: 20}}>
      <h4>You are about to delete your account of Bumpy Internation Dating App.</h4>
      <div>Please, enter your email to validate your account for deletion</div>
      <TextField
        placeholder='Your email address'
        disabled={state !== 'none'}
        type='email'
        size='small'
        margin='normal'
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <br/>
      {renderActions()}
    </div>
  );
}
