import React from 'react';
import {Card} from '@mui/material';
import {Form} from "./Form";

function Main() {
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div style={{maxWidth: 600}}>
        <Card variant="elevation">
          <Form/>
        </Card>
      </div>
    </div>
  );
}

export default Main;
