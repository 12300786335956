import axios from 'axios';

const host = 'https://api-v3.bumpy.dating';

export function emailCodeRequest(email: string): Promise<void> {
  return axios.post(host + '/sign/in', {email});
}

export function emailCodeConfirm(email: string, code: string): Promise<string> {
  return axios.post(host + '/sign/in', {email, code, flow: 'in', user_device: {
      os: 'web',
      os_version: '1',
      manufacturer: 'none',
      product: 'none',
      device_uuid: 'none',
  }})
    .then((response) => {
      return response.data.result.token.access;
    });
}

export function deleteAccount(token: string): Promise<void> {
  return axios.delete(host + '/users/me', {
    headers: {
      Authorization: token,
    },
  });
}
